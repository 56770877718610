<template>
    <div class="form__wrapper">
        <h2>{{ title }}</h2>
        <slot name="inputs" />
        <div class="btn__wrapper">
            <slot name="buttons" />
        </div>
        <div class="social__wrapper" v-if="withSocial">
            <button @click="authProvider('google')" class="icon__wrapper google">
                <GoogleSvg />
            </button>
            <button @click="authProvider('facebook')" class="icon__wrapper facebook">
                <FacebookSvg />
            </button>
        </div>
    </div>
</template>

<script>
    import GoogleSvg from '@/assets/icons/socials/google-g.svg?inline';
    import FacebookSvg from '@/assets/icons/socials/facebook-f.svg?inline';
    import { AuthApi } from '@/api';
    import LocalStorage from '@/helpers/LocalStorageHelper';

    export default {
        name: 'AuthForm',
        components: { GoogleSvg, FacebookSvg },
        props: {
            title: {
                type: String,
                default: '',
            },
            referralUserId: {
                type: [null, String],
                default: null,
            },
            withSocial: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            async authProvider(provider) {
                try {
                    const response = await AuthApi.socialRedirect(provider);
                    if (response.url) {
                        LocalStorage.create('referralUserId', this.referralUserId);
                        window.location.href = response.url;
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .form__wrapper {
        width: 100%;
        padding: 14px;
        max-width: 592px;

        background: $white;
        box-shadow: $box-shadow-dark;

        border-radius: $br-10;

        h2 {
            margin-bottom: 30px;
        }

        .btn__wrapper {
            @include row-align-center-justify-start;

            margin-top: 18px;
        }

        .social__wrapper {
            @include row-align-center-justify-center;

            position: relative;

            margin-top: 18px;
            padding-top: 18px;

            &::before {
                content: '';
                position: absolute;

                top: 0;

                height: 1px;
                width: 100%;

                background: $grey-line;
            }

            .icon__wrapper {
                @include row-align-center-justify-center;

                border-radius: $br-100;
                box-shadow: $box-shadow-dark;

                padding: 10px;

                &.google {
                    background: #c54741;
                    margin-right: 7px;
                }

                &.facebook {
                    background: #3b5998;
                    margin-left: 7px;
                }
            }
        }
    }

    @include media($lg) {
        .form__wrapper {
            padding: 20px;

            h2 {
                font-size: $font-32;
            }
        }
    }
</style>
